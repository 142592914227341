<template>
<div id="bottom-bar">

  <v-layout  wrap v-if="unavailable!=true" class="bottom-bar">
    <v-flex md1 class="hidden-sm-and-down text-xs-center">
      <v-img width="56" class="ma-auto" src="https://res.cloudinary.com/my-room-abroad/image/upload/q_auto,fl_lossy,f_auto/v1537411386/homepage/Logo_square.png" alt="My Room Abroad logo">
      </v-img>
    </v-flex>
    <v-flex md3 class="hidden-sm-and-down ellipsis-title text-xs-center">
      {{room.general.title}}
    </v-flex>

    <v-flex md2 class="hidden-sm-and-down text-xs-center">
      <v-dialog v-model="paymentDialog" width="500">
        <v-btn slot="activator" flat color="primary" dark>
          {{$t("bottomBar.detailsPrice")}}
        </v-btn>

        <v-card>
          <v-card-title class="headline" primary-title>
            {{$t('userRequest.payment')}}

          </v-card-title>

          <v-card-text>
            <v-layout  wrap class="ma-2">
              <v-flex xs12 class="subheading">
                {{$t("bottomBar.payToMRA")}}
              </v-flex>
            </v-layout>
            <v-layout  wrap class="ma-2">

              <v-flex xs7>
                {{$t("userRequest.firstRent")}}
              </v-flex>
              <v-flex xs5 class="text-xs-right" >
                {{room.billing.price}} NT$
              </v-flex>
            </v-layout>
            <v-layout  wrap class="ma-2" v-if="utilities.name=='Fixed monthly amount'">

              <v-flex xs7>
                {{$t("billing.utilities")}}
              </v-flex>
              <v-flex xs5 class="text-xs-right">
                {{utilities.amount}} NT$
              </v-flex>
            </v-layout>

            <v-layout  wrap class="ma-2">
              <v-flex xs7>

                <v-tooltip right>
                  <span slot="activator">{{$t("userRequest.fee")}} <span class="MRAIcon_info"></span></span>
                  <span>{{$t("userRequest.feeHelp1")}}
                    <br>{{$t("userRequest.feeHelp2")}}
                    <br>{{$t("userRequest.feeHelp3")}}
                    <br>{{$t("userRequest.feeHelp4")}}
                    ` </span>
                </v-tooltip>
              </v-flex>
              <v-flex xs5 class="text-xs-right">
                {{commission}} NT$
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-layout  wrap class="ma-2">
              <v-flex xs7 class="font-weight-bold">
                {{$t("userRequest.total")}}
              </v-flex>
              <v-flex xs5 class="text-xs-right" id="price">
                {{total}} NT$
              </v-flex>
            </v-layout>
            <v-layout  wrap class="ma-2" v-if="currency!='TWD'">
              <v-flex xs7 class="font-weight-bold">
                {{$t('userRequest.total')}} {{currency}}
              </v-flex>
              <v-flex xs5 class="text-xs-right">
                {{totalEur}} {{currency}}
              </v-flex>
            </v-layout>
          </v-card-text>


          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" flat @click="paymentDialog = false">
              {{$t("uploads.close")}}
            </v-btn>
            <v-btn color="primary" @click="bookingDetails = true">
              {{$t("bottomBar.book")}}

            </v-btn>

          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-flex>
    <v-flex xs3 md2 class="text-xs-center">
      <v-layout class="justify-center">
        <span class=""><span class="title"> {{room.billing.price}} NT$ </span><span class="hidden-sm-and-down">{{$t("bottomBar.perMonth")}}</span></span>
      </v-layout>
      <!-- <v-layout class="justify-center" v-if="currency!='TWD'"> -->
        <!-- <span class="caption">~{{eurPrice}} {{currency}}</span> -->

      <!-- </v-layout> -->
    </v-flex>

    <v-flex xs9 class="text-xs-right text-sm-center" md4>
      <v-btn color="red" slot="activator" fab small dark @click="addFavorite">
        <v-icon>favorite</v-icon>
      </v-btn>
      <v-btn color="rgb(255,192,0)"  class="hidden-xxs-only" dark @click="checkVisits" id="visit1" v-if="showVisits">visit</v-btn>
      <v-btn color="primary" class="pa-0" dark @click="checkUserBookings" id="book1">
        <v-layout wrap pa-0>
          <v-flex xs12>
            {{$t("bottomBar.book")}}
          </v-flex>
          <v-flex xs12 class="smaller-text">
            It only takes 2 min
          </v-flex>
        </v-layout>
        <v-layout wrap>
        </v-layout>
      </v-btn>
      <v-layout  justify-center>
        <v-dialog v-model="bookingDetails" persistent max-width="500px">
          <v-card>
            <v-card-title>
              <span class="headline">{{$t("bottomBar.bookingDates")}}</span>
            </v-card-title>
            <v-card-text>
              <v-layout  wrap>
                <v-flex xs6 class="pa-2 no-pa-top">
                  <v-text-field v-model="mid" disabled :label="$t('userRequest.mid')" prepend-icon="flight_land"></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-2 no-pa-top">
                  <v-text-field disabled v-model="mod" :label="$t('userRequest.mod')" prepend-icon="flight_takeoff"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout justify-center  wrap>

                <DatePicker :mid="mid" :mod="mod" :noButtons="true" :minDate="minDate" @saveDate="updateDates"></DatePicker>
              </v-layout>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" flat @click.native="bookingDetails = false">{{$t("uploads.back")}}</v-btn>
              <v-btn color="primary" id="book4":disabled="disabled" @click.native="bookRoom('book')"><div class="" id="book4">
                {{$t("bottomBar.book")}}
              </div></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-flex>
  </v-layout>
  <v-layout justify-center align-center style="height: 100%" v-else wrap>
        UNAVAILABLE
  </v-layout>
  <v-dialog v-model="showVisitAlert" max-width="500">
    <v-card>
      <v-card-title class="headline">Minimal visit policy</v-card-title>

      <v-card-text>
        At My Room Abroad we believe in wasting a minimum amount of time!
        <br>Doing a single visit takes you on average 30min. To make life easier for you, we ask our landlords to upload video & pictures and detailed information which can serve as a virtual tour.
        <br>
        Before requesting a visit please look through all the information available on the website.
        As to avoid useless visits and time waste, you can request maximum <em class="font-weight-bold">3 visits</em> on My Room Abroad.
        <br>
        Furthermore if anyone books the room in meanwhile, you will loose your chance at this house.
      </v-card-text>

      <v-card-actions>
        <v-btn :disabled="disableVisitButtons" color="secondary" @click="showVisitAlert=false">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="rgb(255,192,0)" id="visit2" flat="flat" @click="checkUserVisits" :disabled="disableVisitButtons">
          Visit
        </v-btn>

        <v-btn color="primary" flat="flat" id="book2" @click="checkUserBookings" :disabled="disableVisitButtons">
          {{$t("bottomBar.book")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="tooManyVisitsDialog" max-width="500">
    <v-card>
      <v-card-title class="headline">Maximum number of visits requested</v-card-title>

      <v-card-text>
        You have already requested 3 visits on My Room Abroad, which is our limit.
        As too avoid wasting time on useless uninformed visits, we have limited the number of visits on our platform to 3.
        If you believe something wrong happened or have a good reason to make an exception for your case, please send us a message.
        You can however book your room directly afer doing a virtual tour on My Room Abroad.
      </v-card-text>

      <v-card-actions>
        <v-btn color="secondary" @click="tooManyVisitsDialog=false">Cancel</v-btn>
        <v-spacer></v-spacer>

        <v-btn color="primary" id="book3" flat="flat" @click="checkUserBookings" :disabled="disableVisitButtons">
          {{$t("bottomBar.book")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="visitDialog" persistent max-width="500px">
    <v-card>
      <v-card-title>
        <span class="headline">Your Move in & Move out dates</span>
      </v-card-title>
      <v-card-text>
        <v-layout  wrap>
          <v-flex xs6 class="pa-2 no-pa-top">
            <v-text-field v-model="displayDates.mid" disabled :label="$t('userRequest.mid')" prepend-icon="flight_land"></v-text-field>
          </v-flex>
          <v-flex xs6 class="pa-2 no-pa-top">
            <v-text-field disabled v-model="displayDates.mod" :label="$t('userRequest.mod')" prepend-icon="flight_takeoff"></v-text-field>
          </v-flex>
        </v-layout>
        <v-layout justify-center  wrap>

          <DatePicker :mid="mid" :mod="mod" :noButtons="true" :minDate="minDate" @saveDate="updateDates"></DatePicker>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" flat @click.native="visitDialog = false">{{$t("uploads.back")}}  </v-btn>
        <v-btn color="rgb(255,192,0)" id="visit3" dark :disabled="disabled" @click.native="bookRoom('visit')">visit</v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-snackbar
    v-model="showSnackbar"
    color="primary"
    :timeout="6000"

  >
    Added to saved listings
    <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
  </v-snackbar>

</div>
</template>
<script>
import axios from 'axios'
import DatePicker from '../shared/DatePicker.vue'
import moment from 'moment'
import {
  mapGetters,
} from 'vuex'
import * as types from '../../store/types'


export default {
  name: "",
  props: ['room', 'fx', 'currency', 'apartment'],
  data: () => ({
    paymentDialog: false,
    bookDialog: false,
    stepper: {
      active: 'first',
      first: false,
      second: false,
    },
    disableVisitButtons:false,
    datesTrigger: false,
    datesSet: false,
    serviceFee: .2,
    showPaymentDetails: false,
    bookingDetails: false,
    // midToSend: "",
    // modToSend: "",

    showVisitAlert: false,
    visitDialog: false,
    tooManyVisitsDialog:false,
    mid:"",
    mod:"",
    displayDates: {
      mid: "",
      mod: "",
    },
    showSnackbar:false,
  }),



  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    showVisits: function() {
      console.log(moment().add(1, 'week'),'visit date');
      if (moment(this.room.general.nextAvailability).isBefore(moment().add(1, 'week'))) {
        return true
      } else {
        return false
      }
    },
    minDate() {
      if(this.$route.query.mid!=undefined && this.room.acceptSummer==true && moment(this.mid).isBetween(moment().month("May").date('31'),moment().month("August").date('1')) && moment(this.room.general.nextAvailability).isAfter(moment().month('July').date('31')) && this.summerAv==true){
        return moment(this.$route.query.mid).date(1).format('YYYY-MM-DD')
      } else if (moment().isBefore(moment(this.room.general.nextAvailability))) {
        return moment(this.room.general.nextAvailability).format('YYYY-MM-DD')
      } else {
        return moment().format('YYYY-MM-DD')
      }
    },
    summerAv(){
      if (this.room.summerBooked!=true && this.room.septBooked==true){
        return true
      } else {
        return false
      }
    },
    midToSend(){
      if (this.mid!="" && moment(this.mid).isValid()){
        return moment(this.mid).valueOf()
      } else {
        return ""
      }
    },
    modToSend(){
      if (this.mod!="" && moment(this.mod).isValid()){
        return moment(this.mod).valueOf()
      } else {
        return ""
      }
    },
    disabled: {
      get: function() {
        if (!isNaN(this.midToSend) && !isNaN(this.modToSend) && this.modToSend != "") {
          return false
        } else {
          return true
        }
      },
      set: function() {

      }
    },
    roomPrice: function() {
      // console.log("GET ROOMPRICE")
      let t = Number(this.room.billing.price)
      return t
    },
    unavailable(){
      if (this.room.draft==true || this.apartment.draft==true){
        return true
      } else {
        return false
      }
    },
    utilities() {
      let t = {}
      if (this.$route.name == "details") {
        // console.log(this.apartment.contractDetails.utilities,'contractdetails');
        t.name = this.apartment.contractDetails.utilities
        if (this.apartment.contractDetails.utilitiesAmount && this.apartment.contractDetails.utilities == 'Fixed monthly amount') {
          t.amount = parseInt(this.apartment.contractDetails.utilitiesAmount)
        } else {
          t.amount = 0
        }

      } else {
        t.name = this.room.billing.utilities
        if (this.room.billing.utilitiesAmount && this.room.billing.utilities == 'Fixed monthly amount') {
          t.amount = parseInt(this.room.billing.utilitiesAmount)
        } else {
          t.amount = 0
        }
      }
      return t
    },
    eurPrice: function() {
      return Math.ceil(this.fx * this.roomPrice)
    },
    commission: function() {
      let comm = Math.ceil((((this.roomPrice+this.utilities.amount) * this.serviceFee) + this.utilities.amount + this.roomPrice) * this.transactionCost + ((this.roomPrice+this.utilities.amount) * this.serviceFee))
      return comm
    },
    transactionCost() {
      let t=.028
      return t
    },
    total: function() {
      return this.roomPrice + this.commission + this.utilities.amount
    },
    totalEur: function() {
      return Math.ceil(this.fx * this.total)
    },
    disabledDates: function() {
      let tempDisabledDates = []
      for (var tenant in this.allTenants) {
        if (this.allTenants.hasOwnProperty(tenant)) {
          let tempTenant = this.allTenants[tenant]
          // console.log('inside the of the alltenants')
          let currentDate = new Date(tempTenant.moveInDate)
          // console.log('currentDate:' + currentDate)
          while (currentDate.getTime() <= new Date(tempTenant.moveOutDate).getTime()) {
            tempDisabledDates.push(moment(currentDate).format('YYYY-MM-DD'))
            currentDate = new Date(currentDate.getTime() + 86400000)
          }
        }
      }
      // console.log(tempDisabledDates)
      return tempDisabledDates
    },
    // mid: {
    //   get: function() {
    //     if (this.$route.query.mid && moment(this.$route.query.mid).isValid()) {
    //       return moment(this.$route.query.mid)
    //     } else if (this.$route.query.mod && parseFloat(this.$route.query.mid)!= NaN ) {
    //       return new Date(parseFloat(this.$route.query.mid))
    //     } else {
    //       return ""
    //     }
    //   },
    //   set: function(val) {
    //     // console.log('set the mid:',val)
    //     this.midToSend = new Date(val).getTime()
    //     if (!isNaN(this.midToSend)) {
    //       this.displayDates.mid = moment(val).format('DD MMM YYYY')
    //     } else {
    //       this.displayDates.mid = ""
    //     }
    //     if (this.midToSend != "" && this.modToSend != "" && !isNaN(this.midToSend) && !isNaN(this.modToSend)) {
    //       this.disabled = false
    //     } else {
    //       this.disabled = true
    //     }
    //     return (val)
    //   }
    // },
    // mod: {
    //   get: function() {
    //     if (this.$route.query.mod && moment(this.$route.query.mod).isValid()) {
    //       return moment(this.$route.query.mod)
    //     } else if (this.$route.query.mod && parseFloat(this.$route.query.mod)!= NaN ) {
    //       return new Date(parseFloat(this.$route.query.mid))
    //     } else {
    //       return ""
    //     }
    //   },
    //   set: function(val) {
    //     // console.log('set The mod')
    //     // console.log(val)
    //     this.modToSend = new Date(val).getTime()
    //     if (!isNaN(this.modToSend)) {
    //       this.displayDates.mod = moment(val).format('DD MMM YYYY')
    //     } else {
    //       this.displayDates.mod = ""
    //     }
    //     if (this.midToSend != "" && this.modToSend != "" && !isNaN(this.midToSend) && !isNaN(this.modToSend)) {
    //       this.disabled = false
    //     } else {
    //       this.disabled = true
    //     }
    //     return (val)
    //   }
    // },
  },
  methods: {
    checkVisits() {
      this.showVisitAlert = true;
      dataLayer.push({
        'event':'clickVisit1',
      })

    },
    checkUserFavs(){
      for (var id in this.user.favs) {
        if (this.user.favs.hasOwnProperty(id)) {
          if (this.user.favs[id].url==this.$route.path){
            return true
          }
        }
      }
    },
    addFavorite(){
      this.showSnackbar=true
      if(this.user!=undefined && this.user.userName!=undefined && this.user.id!=undefined){
        console.log(this.user.userName);
        console.log('add to fav');
        let check=this.checkUserFavs()
        if (check!=true){
          this.favAdded=true
          // console.log(this.$route.url);
          let fav={
            url:this.$route.path,
            ts:moment().format('YYMMDD')
          }
          if (this.user.favs!=undefined){
            this.user.favs.temp=fav
          } else {
            this.user.favs={temp:fav}
          }
          console.log(fav);
          axios.post('users/'+this.user.id+'/favs.json?auth=' + this.$store.state.auth.idToken,fav)

        } else {
          console.log('already exists');
        }
      } else {
        console.log('sign up');
        this.$router.push('/signUp')
      }
    },
    updateDates(ar) {
      // console.log('array:' ,ar , 'arraylength: ',ar.length)
      if (ar.length == 1) {
        this.mid = ar[0]
      } else {
        this.mid = ar[0]
        this.mod = ar[1]
      }
    },


    checkUserVisits() {
      if (this.user && this.user.visits!=undefined){
        if (Number(this.user.visits)<1){
          this.tooManyVisitsDialog=true
          this.checkVisits=false
          } else {
          this.visitDialog=true
          this.checkVisits=false
        }
      } else {
        this.visitDialog=true
        this.checkVisits=false
      }
    },
    checkUserBookings() {
      if (!this.user.bookingRequestOut || this.user.bookingRequestOut == "") {
        this.bookingDetails = true

      } else if (this.user.bookingRequestOut) {
        // console.log('you have a booking');
        axios.get('/bookingRequests/' + this.user.bookingRequestOut + '.json')
          .then(res => {
            // console.log(res)
            if (res.data.status == "Accepted" && moment().isBefore(moment(res.data.dates.mod))) {
              alert("you already have a confirmed booking. Please go check it out.")
            } else if(res.data.status == "Paid" && moment().isBefore(moment(res.data.dates.mod))){
              alert("you already have a confirmed booking. Please go check it out.")
            } else if (res.data.status == "Declined" || res.data.status == "Past" || res.data.status == "Canceled") {
              this.bookingDetails = true
            } else if (res.data.status == "Requested" && (new Date(res.data.dateRequested).getTime() + 48 * 60 * 60 * 1000) < new Date().getTime()) {
              axios.patch('bookingRequests/' + this.user.bookingRequestOut + '.json' + '?auth=' + this.$store.state.auth.idToken, {
                  status: 'Past'
                })
                .catch(err => {
                  console.log('error: ', err);
                })
              axios.patch('/users/' + this.request.tenant.id + '.json' + '?auth=' + this.$store.state.auth.idToken, {
                  bookingRequestOut: ""
                })
                .catch(err => {
                  console.log('error: ', err);
                })
              this.bookingDetails = true
            } else if(moment(res.data.dates.mod).isBefore(moment())) {
              this.bookingDetails = true
            }
              else {
              // console.log('already have a booking');
              alert("You have already booked a room. First cancel your room if you want to change or wait for the landlord to respond. He will reply within 48hrs of your request.")
            }
          })
          .catch(err => {
            console.log('error: ', err);
          })

      }

    },
    bookRoom(data) {
      let id = ""
      let type = ""
      if (this.midToSend != "" && this.modToSend != "") {
        // console.log(this.room.type)
        if (this.$route.name == "details") {
          id = this.$route.params.roomId
          type = "room"
        } else if (this.$route.name == "studioDetails") {

          // console.log('Its a studio')
          id = this.$route.params.studioId
          type = "studio"
        } else if (this.$route.params.type == "apartment") {
          // console.log("it's an apartment");
          id = this.$route.params.aptId
          type = "apartment"
        }
        // console.log('before pushing the router')
        // console.log(id)
        let route={
          name: 'bookingRequest',
          params: {
            id: id,
            mid: this.midToSend,
            mod: this.modToSend,
            type: type
          }
        }
        if (data=='visit'){
          dataLayer.push({
            'event':'clickVisit3',
          })

          route.query={
            visit:'true'
          }
        } else {
          dataLayer.push({
            'event':'clickBook4',
          })
        }
        this.$router.push(route)
      } else {

        this.error = "Please enter your dates"
      }
    }
  },
  components: {
    DatePicker
  }
}
</script>
<style lang="scss" scoped>
#bottom-bar {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #ffffff;
    height: 56px;
    min-height: 50px;
    transition: 600ms;
    z-index: 10;
    box-shadow: 0 2px 9px 0 rgba(0,0,0,.38);
}
.book-button {
    width: 100%;
}
.bottom-bar {
    height: 100%;
    align-items: center;
}
.default-font {
    color: rgba(0,0,0,.6);
    line-height: 2rem;
}
.price-tag {
    font-size: 0.875rem;
}
.price {
    font-size: 1.5rem;

}
.md-right {
    text-align: right;
}
.paymentDetails div {
    text-align: right;
}
.price-div div {
    text-align: right;
    padding-right: 2em;
}
.payment-dialog {}
.divider {

    margin: 1rem;
}
.subheader {
    margin-top: -1rem;
    margin-bottom: 0.5rem;
}
.my-tooltip {
    position: relative;
    display: inline-block;
}

/* my-tooltip text */
.my-tooltip .my-tooltiptext {
    visibility: hidden;
    width: 350px;
    background-color: rgba(0,0,0,.87);
    color: #fff;
    size: 0.75em;
    padding: 10px;
    border-radius: 6px;
    font-family: 'Roboto', 'Open Sans', 'Sans-serif';
    /* Position the tooltip text - see examples below! */
    position: absolute;
    line-height: 1.3em;
    top: -50px;
    left: -150px;
    z-index: 1;
}
.bottom-bar {}
/* Show the tooltip text when you mouse over the tooltip container */
.my-tooltip:hover .my-tooltiptext {
    visibility: visible;
}
.booking-button {
    padding-right: 1em;
}
.bottom-bar-photo {
    height: 12vh;
    width: auto;
    margin: auto;
}
.show-overflow {
    overflow: visible;
}
.dialog-inner {
    padding: 10px;
}
.dates-box {
    text-align: center;
}
.confirm-dates-button {
    width: 100%;
    margin-left: 0;
}
.confirm-dates-button-wrapper {
    width: 50%;
    margin: auto auto 2em;

}
.ellipsis-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}
.error-text {
    color: red;
}

.smaller-text{
  font-size: .7rem;
  text-transform: none;
}
@media only screen and (max-width: 960px) {
    .price-div div {
        text-align: left;
        padding-left: 1em;
    }

}
@media only screen and (max-width:450px){
  .hidden-xxs-only{
    display:none
  }
}
</style>
