<template>
  <div class="">

  <div id="detailsPage" :data-listingId="$route.params.studioId" :data-listingPrice="parseInt(studio.billing.price)">
  <Navbar></Navbar>
  <Loading v-if="initialLoad"></Loading>
  <!-- BOTTOM BAR!!! -->

  <!-- Main content -->
  <!-- Carousel -->
  <div class="" v-else>
    <Bottombar :room="studio" :fx="fx" :currency="currency" :apartment="studio"></Bottombar>

    <v-layout  justify-center wrap>
      <v-flex xs12 md7 class="">
        <!-- <v-responsive aspect-ratio="1.7721519"> -->

          <v-carousel :cycle="false" height="100%">

            <v-carousel-item v-for="(image, i) in studio.visual.photos" :key="i" :src="baseUrl+image.id" :alt="'My Room Abroad - Shared rooms and studios for international students, exchange students, language teachers and expats in Aisa. '+' '+studio.general.title+' '+image.tag" height="100%" reverse-transition="fade" transition="fade">
              <v-layout align-end justify-center class="full-height"  wrap>
                <span class="title image-text">{{image.text}}</span>
              </v-layout>
            </v-carousel-item>
          </v-carousel>
        <!-- </v-responsive> -->
    </v-flex>
    </v-layout>
  </div>
  <v-container grid-list-lg>
    <v-layout  wrap justify-center>
      <v-flex sm10 md8>
        <!-- Title -->
        <v-layout  wrap justify-center>
          <v-flex xs12>
            <h1 class="display-2 text-xs-center">{{studio.general.title}}</h1>
          </v-flex>
        </v-layout>
        <!-- SUMMARY -->
        <v-divider></v-divider>
        <v-layout  wrap>
          <v-flex xs6 md3 class="summary">
            <span class="MRAIcon_date"></span>
            <p> {{studio.textDate}}</p>
          </v-flex>
          <v-flex xs6 md3 class="summary" v-if="studio.featureList.bedType!='None'">
            <span :class="'MRAIcon_'+snakeStrBedType + '_bed'"></span>
            <p>{{bedType}}</p>
          </v-flex>
          <v-flex xs6 md3 class="summary" v-else>
            <span :class="'MRAIcon_close'"></span>
            <p>{{bedType}}</p>
          </v-flex>
          <v-flex xs6 md3 class="summary">
            <span class="MRAIcon_contract_length"></span>
            <p> {{contractLength}} </p>
          </v-flex>
          <v-flex xs6 md3 class="summary">
            <span class="MRAIcon_number_of_rooms"></span>
            <p>{{$t('uploads.studio')}}</p>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <!-- Long Text Description -->
        <v-layout  wrap>
          <v-flex xs12>
            <v-layout  wrap justify-center>
              <h2 class="text-xs-center display-1 ma-3">{{$t("generalInfo.description")}}</h2>
            </v-layout>
        <!-- Views -->
        <v-layout align-center pb-2  wrap>
          <v-flex xs2 sm1 class="text-xs-center pr-2">
            <v-icon color="primary">remove_red_eye</v-icon>
          </v-flex>
          <v-flex xs10 sm11>
            <v-layout   wrap>
              <v-flex xs12 class=" pa-0 font-weight-bold view-title">
                Popular this week
              </v-flex>
              <v-flex xs12 class="pa-0">
                <span class="font-weight-bold">{{viewsThisWeek}}</span> people are keeping an eye on this listing
              </v-flex>

            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout pa-2  wrap>

          <v-flex xs12 v-if="user.roleValue>70" class="pa-0">
            Admin: {{realViewsThisWeek}} real views this week
            <br>
            # total Views: {{views}}
            <br>
            <span v-if="ll!=null">
              Landlord: <a :href="'/users?id='+studio.general.landlordId"> {{ll.userName}}</a> <span v-if="ll.nickname!=undefined"> - ({{ll.nickname}})</span> <span v-if="admin!=null">  - ( {{admin.userName}})</span>
            </span>
          </v-flex>
        </v-layout>
        <!-- Trust -->
        <Trust :trust="trustVal" :bookings="llBookings" :acceptance="acceptance" :respRate="respRate"></Trust>
        <v-btn fab color="primary" small dark :href="'https://www.facebook.com/sharer/sharer.php?u=https://myroomabroad.com'+$route.fullPath">
          <img src="@/assets/facebook.svg" class="social-icon" background="" alt="Facebook"></span>
        </v-btn>
        <div class="" v-if="user.roleValue>40">
          <v-btn color="primary" :href="'/landlordDashboard/'+studio.general.landlordId">Landlord Dashboard</v-btn>
          <v-btn color="primary" :href="'/studio'+'?studioId='+$route.params.studioId">Edit Studio</v-btn>
          <v-btn color="primary" :href="'https://gps-coordinates.org/my-location.php?lat='+studio.general.location.lat+'&lng='+studio.general.location.lng">See address</v-btn>
          <v-btn color="primary "flat v-if="user.roleValue>79" :href="'/ratings?id='+$route.params.studioId+'&type=studio&ll='+studio.general.landlordId">+ Review</v-btn>

            </div>

            <v-layout  wrap>
              <v-flex xs12>
                <h3 class='title ma-1 uppercase'>{{$t("uploads.studio")}} {{$t("generalInfo.description")}}</h3>
                <v-layout  wrap class="pa-2 ">
                  <v-flex xs12 class="pa-0" v-for="(el,index) in studioDesc" :key="index">
                    {{el}}
                  </v-flex>
                </v-layout>

              </v-flex>
            </v-layout>

          </v-flex>
        </v-layout>
        <!-- VIDEO -->
        <v-layout  wrap class="ma-3 no-ma-sides" v-if="video!=undefined && video.includes('youtube')">
          <v-flex xs12>
            <h3 class='title ma-1 uppercase'>{{$t("details.videoTour")}}</h3>

            <v-img justify-center :aspect-ratio="1.7721519" class="ma-3">
              <iframe
                      :src="video"
                      height="100%"
                      width="100%"
                      frameborder="0"
                      allow="autoplay; encrypted-media"
                      allowfullscreen>

                    </iframe>
            </v-img>
          </v-flex>
        </v-layout>

        <!-- Features -->
        <v-layout  wrap>
          <v-flex xs12>
            <v-layout  wrap justify-center class="features">
              <h2 class="text-xs-center display-1 ma-3">{{$t("uploads.features")}}</h2>
            </v-layout>
            <!-- Room Features -->
            <v-layout  wrap class="room-features">
              <v-flex xs12>
                <h3 class='title ma-1 uppercase'>{{$t("features.furniture")}}</h3>
              </v-flex>
              <v-flex xs6 sm4 md3 class="feature-list-item">
                <span :class="'MRAIcon_'+snakeStrBedType + '_bed' "> </span>
                {{bedType}}
              </v-flex>
              <Feature v-for="(feature, index) in studioFurniture" :key="index" :index="index" :feature="feature">
              </Feature>
            </v-layout>
            <!-- Apartment Features -->
            <v-layout  wrap class="studio-features">
              <v-flex xs12>
                <h3 class='title ma-1'>{{$t('uploads.features')}}</h3>
              </v-flex >
              <v-flex xs12 class="">
                <v-layout  wrap>

                  <Feature v-for="(feature, index) in studioFeatures" :key="index" :index="index" :feature="feature">
                  </Feature>
                  <v-flex xs6 sm4 md3 class="feature-list-item">
                    <span :class="'MRAIcon_size'"></span>
                    <span v-if="studio.featureList.size=='small'"> {{$t("details.small")}}: <20 m&#178; (< 7 {{$t('uploads.ping')}})</span>
                    <span v-if="studio.featureList.size=='average'"> {{$t("details.average")}}: 20-40 m&#178; (7-13 {{$t('uploads.ping')}})</span>
                    <span v-if="studio.featureList.size=='large'"> {{$t("details.large")}}: >40 m&#178; (> 13 {{$t('uploads.ping')}})</span>
                  </v-flex>
                  <v-flex xs6 sm4 md3 class="feature-list-item">
                    <span><v-icon small>call_made</v-icon> </span> {{$t("generalInfo.floor")}}: {{studio.general.floor}}
                  </v-flex>

                </v-layout>
              </v-flex>
              <v-flex xs12>

                <v-layout  wrap class="ma-3 no-ma-sides">
                  <v-flex xs12 class="no-pa-sides">
                    <h4 class="subheading">{{$t("details.bathroom")}}</h4>
                  </v-flex>
                  <v-flex xs12 class="no-pa-sides">
                    <v-tooltip v-for="(bathroom, index) in studio.featureList.bathrooms" :key="bathroom.index" bottom>
                      <div slot="activator">
                        {{$t("details.bathroom")}} {{index+1}}: {{bathroomType(bathroom.type)}} <span class="MRAIcon_info"></span>
                      </div>
                      <span v-if="bathroom.type.includes('Dry')">{{dryBathroom}}</span>
                      <span v-if="bathroom.type.includes('Wet')">{{wetBathroom}}</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12>
                <v-layout  wrap class="no-ma-sides">
                  <v-flex xs12 class="no-pa-sides">
                    <h4 class="subheading">{{$t("details.kitchen")}}</h4>
                  </v-flex>
                  <v-flex xs12 class="no-pa-sides">
                    <p>{{$t("features.kitchen")}}: {{kitchenType}}<br>
                      <small v-if="studio.featureList.kitchenType=='Basic cooking facilities'">{{$t("details.basicKitchen")}}</small>
                      <small v-if="studio.featureList.kitchenType=='None'">{{$t("details.noKitchen")}}</small></p>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <!-- FLEXIBLE PRICES -->
        <v-layout  wrap v-if="studio.billing.flexPrice==true">
          <v-flex xs12>
            <v-layout  wrap justify-center class="features">
              <h2 class="text-xs-center display-1 ma-3">{{$t("pricing.flexprice")}}</h2>
            </v-layout>
            <v-layout  wrap>
              <v-flex xs4 class="title">
                {{$t('pricing.contractLength')}}
              </v-flex>
              <v-flex xs4 class="title">
                {{$t('billing.rent')}}
              </v-flex>
              <v-flex xs4 class="title">
                {{$t('billing.deposit')}}
              </v-flex>
            </v-layout>
            <v-layout  wrap v-if="cLengthVal<3">
              <v-flex xs4>
                {{$t('pricing.monthly')}}
              </v-flex>
              <v-flex xs4>
                {{studio.billing.flexPricing.month}} NT$
              </v-flex>
              <v-flex xs4>
                {{studio.billing.flexPricing.monthD}} NT$
              </v-flex>
            </v-layout>
            <v-layout  wrap v-if="cLengthVal<5">
              <v-flex xs4>
                {{$t('pricing.three')}}
              </v-flex>
              <v-flex xs4>
                {{studio.billing.flexPricing.three}} NT$

              </v-flex>
              <v-flex xs4>
                {{studio.billing.flexPricing.threeD}} NT$

              </v-flex>
            </v-layout>
            <v-layout  wrap v-if="cLengthVal<12">
              <v-flex xs4>
                {{$t('pricing.five')}}
              </v-flex>
              <v-flex xs4>
                {{studio.billing.flexPricing.five}} NT$

              </v-flex>
              <v-flex xs4>
                {{studio.billing.flexPricing.fiveD}} NT$

              </v-flex>
            </v-layout>
            <v-layout  wrap>
              <v-flex xs4>
                {{$t('pricing.year')}}
              </v-flex>
              <v-flex xs4>
                {{studio.billing.flexPricing.year}} NT$

              </v-flex>
              <v-flex xs4>
                {{studio.billing.flexPricing.yearD}} NT$

              </v-flex>
            </v-layout>

          </v-flex>
          <v-flex xs12>
            <span>Standard contracts in Taipei are for +12 months. This landlord exceptionally accepts shorter term rentals.</span>
          </v-flex>

        </v-layout>
        <v-divider></v-divider>

        <!-- Contract information -->
        <v-layout  wrap>
          <v-flex xs12>
            <v-layout  wrap justify-center class="features">
              <h2 class="text-xs-center display-1 ma-3">{{$t("details.contractInfo")}}</h2>
            </v-layout>
            <v-layout  wrap>
              <v-flex xs12 sm6>
                {{$t("generalInfo.studioName")}}: {{studio.general.name}}
              </v-flex>
              <v-flex xs12 sm6>
                {{$t("userRequest.minLength")}}: {{contractLength}}<br>
                <!-- <small v-if="studio.contractDetails.contractLength=='12 months'">{{$t("details.minLengthLong")}}</small>
                <small v-if="studio.contractDetails.contractLength=='Open Contract'">{{$t("details.minLengthLong")}}</small> -->
                <v-tooltip bottom v-if="studio.contractDetails.contractLength=='12 months' || studio.contractDetails.contractLength=='Open contract'">
                   <v-icon slot="activator" color="secondary" small dark >info</v-icon>
                   <span>{{$t("details.minLengthLong")}}</span>
                 </v-tooltip>    </v-flex>
              <v-flex xs12 sm6>
                {{$t("userRequest.contractType")}}: {{contractFlexibility}}
              </v-flex>
              <v-flex xs12 sm6>
                {{$t("contractDetails.contractLanguage")}}: {{contractLanguage}}<br>
              </v-flex>
              <v-flex xs12 sm6>
                {{$t("billing.rent")}}: NT${{studio.billing.price}} (~ {{currency}} {{eurPrice}})
              </v-flex>
              <v-flex xs12 sm6>
                {{$t("billing.deposit")}}: NT${{studio.billing.deposit}}
              </v-flex>
              <v-flex xs12 sm6 v-if="studio.billing.utilities=='Fixed monthly amount'">
                {{$t("billing.rentIncluding")}}: NT${{Number(studio.billing.price)+Number(studio.billing.utilitiesAmount)}}
              </v-flex>
              <v-flex xs12 sm6>
                <div v-if="studio.billing.utilities">{{$t("billing.utilities")}}: {{utilities}}</div>
                <div v-if="studio.billing.utilities=='Fixed monthly amount'"> {{$t("billing.utilitiesAmount")}}: NT${{studio.billing.utilitiesAmount}}{{$t("bottomBar.perMonth")}}</div>
                <div v-if="studio.billing.utilities=='Split by tenants'"> {{$t("details.utilitiesSplit")}}</div>
              </v-flex>
              <v-flex xs12 sm6 v-if="studio.billing.utilitiesComment!=''">
                {{$t("billing.utilitiesComment")}}: {{studio.billing.utilitiesComment}}

              </v-flex>
              <v-flex xs12 sm6 v-if="studio.billing.couplesAllowed">
                <span class="houserules-item"><span class="MRAIcon_couple"></span>{{$t("billing.couplesAllowed")}}</span>
              </v-flex>
              <v-flex xs12 sm6 v-if="!studio.billing.couplesAllowed">
                <span class="houserules-item"><span class="MRAIcon_forbidden"></span>{{$t("details.couplesNotAllowed")}}</span>
              </v-flex>
              <v-flex xs12 sm6 v-if="studio.billing.couplesAllowed">
                <span class="houserules-item">{{$t("billing.couplesExtraUtilities")}}: {{studio.billing.couplesExtraUtilities}} NT${{$t("bottomBar.perMonth")}}</span>
              </v-flex>

            </v-layout>
            <v-layout  wrap>
              <v-flex xs12>
                <h3 class='title ma-1 uppercase'>{{$t("contractDetails.rules")}}</h3>
              </v-flex>
              <v-flex xs12 v-if="studio.featureList.cleaned==true">
                <span class="houserules-item"><span class="MRAIcon_cleaning"></span>Professional cleaning before arrival</span>
              </v-flex>
              <v-flex xs12 sm6 v-else>

                <v-tooltip bottom>
                  <span slot="activator" class="houserules-item"><span class="MRAIcon_cleaning"></span> NO professional cleaning before arrival! You might need to clean it yourself when you arrive</span>
                  <span>You can request professional cleaning after your booking has been accepted</span>
                </v-tooltip>

              </v-flex>
              <v-flex xs12>
                <v-layout  wrap>
                  <v-flex xs12 sm6 v-for="(rule,index) in studio.contractDetails.rules" :key="index" v-if="rule.value">
                    <span class="houserules-item" ><span :class="'MRAIcon_'+index"></span>{{$t("contractDetails.rulesTexts."+index)}}</span>
                  </v-flex>
                  <v-flex xs12 sm6 v-if="smokingAllowed">
                    <span class="houserules-item"><span class="MRAIcon_smoking"></span> {{$t("details.smokingAllowed")}}</span>
                  </v-flex>
                </v-layout>
                <v-expansion-panel class="no-box-shadow">
                  <v-expansion-panel-content>
                    <div slot="header">{{$t('details.moreRules')}}</div>
                    <v-layout  wrap>
                      <v-flex xs12>
                        <span class="houserules-item">{{$t('details.cancelPol')}}:
                          <v-tooltip bottom>
                            <a slot="activator" href="/policies/terms#cancellation" target="_blank">{{cancellation}}</a>
                            <span>{{$t('profile.cancelPolHint')}}</span>
                          </v-tooltip>
                        </span>
                      </v-flex>
                      <v-flex xs12>
                        <span class="houserules-item">{{$t('details.ruleClean')}}</span>
                      </v-flex>
                      <v-flex xs12>
                        <span class="houserules-item">{{$t('details.ruleDocs')}}</span>
                      </v-flex>
                      <v-flex xs12>
                        <span class="houserules-item" v-if="!studioFeatures.cleaningService.value || !studioFeatures.trashService.value">{{$t('details.ruleSchedule')}}</span>
                      </v-flex>
                      <v-flex xs12>
                        <span class="houserules-item">{{$t('contractDetails.otherRules')}}: {{studio.contractDetails.otherRules}}</span>
                      </v-flex>
                    </v-layout>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <!-- Other Tenants -->
        <!-- <v-layout  wrap>
          <v-flex xs12>
            <v-layout  wrap justify-center class="features">
              <h2 class="text-xs-center display-1 ma-3">{{$t('details.otherTenants')}}</h2>
            </v-layout>
            <v-layout  wrap>
              <v-flex md2 sm3>
                <h4>{{$t('uploads.room')}}</h4>
              </v-flex>
              <v-flex md5 sm9>
                <h4>{{$t('generalInfo.currentTenants')}}</h4>
              </v-flex>
            </v-layout>

            <OtherTenant :room="studio" :type="'studio'" :dateSet="dateSet"></OtherTenant>
          </v-flex>
        </v-layout>-->
        <v-layout pa-3  wrap justify-center>
          <v-btn color="primary" id="similarLink" :href="similarLink">
            <v-icon>search</v-icon>
            See more similar listings</v-btn>
        </v-layout>
        <v-layout  wrap>
          <Area :area="area" :type="'Studio'" :url="'/search?propType=studio'"></Area>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
  <!-- Map section -->
  <OpenMapsListings :center="map.center" :zoom="map.zoom">
  </OpenMapsListings>

  </div>
</div>
</template>
<script>
import OtherTenant from './OtherTenant.vue'
import Bottombar from './Bottombar.vue'
import Area from './Area.vue'
import moment from 'moment'
// import ListingMap from './ListingMap.vue'
import OpenMapsListings from './OpenMapsListings.vue'
import axios from 'axios'
import Trust from './Trust.vue'
import {
  mapGetters,
} from 'vuex'
import Loading from '../shared/Loading.vue'
import * as types from '../../store/types'
import Feature from './Feature.vue'
import toSnakeCase from 'to-snake-case'
import config from '../../config'
import firebase from '../../FirebaseApp'
import inside from 'point-in-polygon'


export default {
  name: "",
  data: () => ({
    currency:"EUR",
    cLengthVal:12,
    aera:"",
    cancelPolVal:0,
    ll:null,
    admin:null,
    initialLoad:true,
    moreRules: false,
    respRate:0,
    llBR:0,
    asianBathroom: "Wet bathroom. No separation between the shower and the rest of the bathroom. This might be surprising at first.",
    westernBathroom: "Separation between the shower and the rest of the bathroom",
    snakeStrBedType: '',
    otherRoomsInApartment: [],
    map: {
      center: {
        lat: 25.0347833,
        lng: 121.5139323
      },
      zoom: 14
    },
    studio: {
      general:{},
      featureList:{},
      contractDetails:{},
      billing:{},
      visual:{},
    },
    photos: [],
    video: "",
    slide: 0,
    sliding: null,
    studioFurniture: {
      washingMachine: {
        value: false,
        icon: 'MRAIcon_laundry',
        name: 'Washing Machine'
      },
      tv: {
        value: false,
        icon: 'MRAIcon_tv',
        name: 'TV'
      },
      couch: {
        value: false,
        icon: 'MRAIcon_couch',
        name: 'Couch'
      },
      coffeeTable: {
        value: false,
        icon: 'MRAIcon_coffee_table',
        name: 'Coffee Table'
      },
      ac: {
        value: false,
        icon: 'MRAIcon_air_conditioning',
        name: 'A/C'
      },
      fan: {
        value: false,
        icon: 'MRAIcon_fan',
        name: 'Fan'
      },

      curtains: {
        value: false,
        icon: 'MRAIcon_curtains',
        name: 'Curtains'
      },
      mattress: {
        value: false,
        icon: 'MRAIcon_mattress',
        name: 'Mattress'
      },
      pillow: {
        value: false,
        icon: 'MRAIcon_pillow',
        name: 'Pillow'
      },
      sheets: {
        value: false,
        icon: 'MRAIcon_sheets',
        name: 'Bed sheets'
      },
      desk: {
        value: false,
        icon: 'MRAIcon_desk',
        name: 'Desk'
      },
      chair: {
        value: false,
        icon: 'MRAIcon_chair',
        name: 'Chair'
      },
      wardrobe: {
        value: false,
        icon: 'MRAIcon_wardrobe',
        name: 'Wardrobe'
      },
      openHanger: {
        value: false,
        icon: 'MRAIcon_open_hanger',
        name: 'Open Hanger'
      },
      shelve: {
        value: false,
        icon: 'MRAIcon_shelves',
        name: 'Shelve'
      },
    },
    studioFeatures: {
      privateBathroom: {
        value: true,
        icon: 'MRAIcon_private_bathroom',
        name: 'Private Bathroom'
      },
      wifi: {
        value: false,
        icon: 'MRAIcon_wifi',
        name: 'Wifi'
      },
      balcony: {
        value: false,
        icon: 'MRAIcon_balcony',
        name: 'Balcony'
      },
      rooftop: {
        value: false,
        icon: 'MRAIcon_rooftop',
        name: 'Rooftop'
      },
      elevator: {
        value: false,
        icon: 'MRAIcon_elevator',
        name: 'Elevator'
      },
      security: {
        value: false,
        icon: 'MRAIcon_security',
        name: 'Security'
      },
      cleaningService: {
        value: false,
        icon: 'MRAIcon_cleaning',
        name: 'Cleaning Service'
      },
      trashService: {
        value: false,
        icon: 'MRAIcon_trash_service',
        name: 'Trash Service'
      },
      outsideWindow: {
        value: false,
        icon: 'MRAIcon_outside_window',
        name: 'Outside Window'
      },
      innerWindow: {
        value: false,
        icon: 'MRAIcon_inner_window',
        name: 'Inner Window'
      },
    },
    fx: 0.028,
    eurPrice: "...",
    futureTenants: [],
    currentTenants: [],
    roomSaved:false,
    savedRoomId:"",
    smokingAllowed: false,
    on:false,
    llBookings:0,
    dateSet: false,
  }),
  firebase() {
    return {
      rSeen: {
        source:firebase.database().ref('rSeen').orderByChild('id').equalTo(this.$route.params.studioId),
        readyCallback:function(){

        }

      },
    }
  },

  watch: {
    studio: function() {
      this.snakeStrBedType = toSnakeCase(this.studio.featureList.bedType)
      // console.log(this.snakeStrBedType)
    },
    user: function(){
      this.getFx()
    }
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    //
    // this.changeFbDisplay(true)
    console.log('do this');
    console.log(this.user, this.roomSaved,);
    if (this.savedRoomId!=""){
      console.log('Exited at:');
      axios.patch('/rSeen/'+this.savedRoomId+'.json',{exitTS:moment()})
    }
    next()
  },
  methods: {
    getLLBookings(apt){
      axios.get('completedOrders.json?orderBy="landlordId"&equalTo="'+apt.general.landlordId+'"')
      .then(res=>{
        this.llBookings=Object.keys(res.data).length
      })
      axios.get('bookingRequests.json?orderBy="landlordId"&equalTo="'+apt.general.landlordId+'"')
      .then(res=>{
        this.llBR=Object.keys(res.data).length
        let past=0
        for (var id in res.data) {
          if (res.data.hasOwnProperty(id)) {

            if (res.data[id].status.toLowerCase().includes('past')){
              past=past+1
            }
          }
        }
        this.respRate=Math.round((this.llBR-past)/this.llBR*100)
      })

    },

    setArea(location){
      // console.log('SET AREA,');
      if (inside([location.lat,location.lng],[[25.009265, 121.493519],[25.022660, 121.513878],[25.007928, 121.532117],[24.995454, 121.530985]])){
        this.area="yonghe"
      } else if ( inside([location.lat,location.lng],[[25.022660, 121.513878],[25.007928, 121.532117],[25.011120, 121.537355],[25.034589, 121.516956],[25.037567, 121.507363],[25.030265, 121.504245]])){
        this.area='zhongzheng'
      } else if ( inside([location.lat,location.lng],[[25.011120, 121.537355],[25.034589, 121.516956],[25.033037, 121.553444],[25.011379, 121.563730]])){
        this.area="daanStudent"
      } else if ( inside([location.lat, location.lng],[[24.995454, 121.530985],[25.011120, 121.537355],[25.011379, 121.563730],[25.016621, 121.598156],[24.979995, 121.600270],[24.969820, 121.567915]])){
        this.area="wenshan"
      } else if ( inside([location.lat, location.lng],[[25.037619, 121.595279],[25.011379, 121.563730],[25.033037, 121.553444],[25.045204, 121.561669],[25.044696, 121.578818]])){
        this.area="xinyi"
      } else if ( inside([location.lat, location.lng],[[25.045043, 121.532872],[25.045204, 121.561669],[25.044696, 121.578818],[25.052327, 121.578807],[25.074372, 121.569722],[25.075832, 121.543495]])){
        this.area="songshan"
      } else if ( inside([location.lat, location.lng],[[25.045204, 121.561669],[25.033037, 121.553444],[25.034589, 121.516956],[25.045043, 121.532872]])){
        this.area="daanExpat"
      } else if ( inside([location.lat, location.lng],[[25.034589, 121.516956],[25.045043, 121.532872],[25.075832, 121.543495],[25.076026, 121.506507],[25.052189, 121.504726]])){
        this.area="zhongshan"
      } else if ( inside([location.lat, location.lng],[[25.075832, 121.543495],[25.074372, 121.569722],[25.065426, 121.616447],[25.101222, 121.611935],[25.091674, 121.542136]])){
        this.area="neihu"
      } else if ( inside([location.lat, location.lng],[[25.091674, 121.542136],[25.166770, 121.572119],[25.101222, 121.611935],[25.075832, 121.543495],[25.076026, 121.506507],[25.095602, 121.509428]])){
        this.area="shilin"
      } else if ( inside([location.lat, location.lng],[[25.095602, 121.509428],[25.166770, 121.572119],[25.209079, 121.561712],[25.125054, 121.458978]])){
        this.area="beitou"
      } else if ( inside([location.lat, location.lng],[[25.125054, 121.458978],[25.209079, 121.561712],[25.227988, 121.443948],[25.183033, 121.404265]])){
        this.area="tamsui"
      }
    },
    checkLocation(location){
      console.log(location, 'location');
      if (inside([location.lat,location.lng],[[25.030472, 121.524953],[25.021138, 121.522718],[25.026503, 121.513911],[25.032242, 121.522633]])){
        this.studio.general.title=this.studio.general.title+" - Apartment for rent in Downtown Taipei. Near Guting station and NTNU MTC. Connections to NTU, NTUST, TKU, NCCU MLC, Soochow, FJU (Fu Jen)"
      } else if ( inside([location.lat,location.lng],[[25.026971, 121.524968],[25.024511, 121.524436],[25.020971, 121.528294],[25.022678, 121.533488]])){
        this.studio.general.title=this.studio.general.title+" - Apartment rental in Downtown Taipei - near Shida Night Market, NTUST, NTNU MTC, Taipei Tech, NTU"
      } else if ( inside([location.lat,location.lng],[[25.037345, 121.537419],[25.022896, 121.537018],[25.028949, 121.526349],[25.038037, 121.528357]])){
        this.studio.general.title=this.studio.general.title+" - Taipei Rental Apartment near Da'an Park, NTNU, NTU, NTUST, Taipei Tech"
      } else if ( inside([location.lat, location.lng],[[25.014485, 121.552339],[25.022910, 121.538126],[25.037612, 121.538231],[25.020220, 121.561397]])){
        this.studio.general.title=this.studio.general.title+" - Rental Apartment Taipei - near Da'an, TMU Tonghua Night Market and ATT4Fun. Easy transportation to NCCU, NTUST, Shih Chien University, Shih Hsin University"
      } else if ( inside([location.lat, location.lng],[[25.037687, 121.557730],[25.025590, 121.556161],[25.022766, 121.573102],[25.036676, 121.574564]])){
        this.studio.general.title=this.studio.general.title+" - Apartment located near Taipei 101, Xinyi, ATT4Fun, Direct Bus to NCCU, NTUST"
      } else if ( inside([location.lat, location.lng],[[25.022117, 121.534061],[25.015234, 121.525907],[24.995446, 121.536017],[24.995910, 121.546503]])){
        this.studio.general.title=this.studio.general.title+" - Taipei Apartments - biking distance to NTU, NTUST - Gongguan Nightmarket and metro nearby."
      } else if ( inside([location.lat, location.lng],[[25.01803, 121.5117],[24.997460, 121.500728],[24.981543, 121.510915],[24.989618, 121.538048]])){
        this.studio.general.title=this.studio.general.title+" - Taipei Apartment for rent  - Connections to NTUST (Taiwan Tech), NTU, NTNU, Taipei Tech"
      }

    },

    checkQuery(){
      // console.log('route: ', this.$route);
      if (this.$route.query!=undefined && this.$route.query.id!=undefined){
        console.log('post the query RTS:',this.$route.query.rts, "+ID: ",this.$route.query.id);
        axios.post('eOpens.json',{
          user:this.$route.query.id,
          rts: this.$route.query.rts,
        })
      } else {
        console.log("no query worth noting" ,this.$route.query);
      }
    },
    checkUser(name){
      console.log('checkUser');
      if (this.user!=undefined && this.user.roleValue>=20 && name!=undefined){
        axios.delete('rSeen/'+name+'.json')
        .then(()=>{
          console.log('deleted the RSEEN');
        })
      } else {
        if (name!=undefined){
          this.savedRoomId=name
        }
      }
    },
    setRSeen(){
      let userId=localStorage.getItem('user')
      let t = {
        ts:moment(),
        id:this.$route.params.studioId,
        type:'studio'
      }
      console.log('userId:',userId);
      if (userId!=undefined){
        t.user=userId
        console.log( 'post :', t);
        axios.post('rSeen.json',t)
        .then(res=>{
          console.log('successfully added the seen with user');
          this.checkUser(res.data.name)
        })
      } else {
        axios.post('rSeen.json',t)
        .then(res=>{
          let recentSeen=localStorage.getItem('recentSeen')
          if (recentSeen==undefined){
            console.log('recentSeen undefined');
            recentSeen={
              data:[],
              exp:moment().add(7,"days").valueOf()
            }
          } else {
            recentSeen=JSON.parse(recentSeen)
            console.log(recentSeen);
            console.log('in the else ');
            if(parseInt(recentSeen.exp)<moment().valueOf()){
              console.log('reset RecentSeen');
              recentSeen.data = []
              recentSeen.exp = moment().add(7,"days").valueOf()
            }
          }
          recentSeen.data.push(res.data.name)
          console.log('recentSeen:', recentSeen);

          localStorage.setItem('recentSeen',JSON.stringify(recentSeen))
        })
      }
    },
    bathroomType(bathroom){
      if (bathroom.includes('Wet')){
        return this.$t("details.bathroomWet")
      } else {
        return this.$t("details.bathroomDry")
      }
    },
    setContractLengthVal(){
      switch (this.studio.contractDetails.contractLength) {
        case '1 month':
          this.cLengthVal=1
          break;
        case '3 months':
          this.cLengthVal=3
          break;
        case '5 months':
          this.cLengthVal=5
          break;
        case '12 months':
          this.cLengthVal=12
          break;
        default:
          this.cLengthVal=12
      }
    },
    // transforms the youtube direct url into the embed url
    getId(url) {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      var match = url.match(regExp);

      if (match && match[2].length == 11) {
        return match[2];
      } else {
        return 'error';
      }
    },
    getFx(){
      let fxLink="TWD_EUR"
      // console.log('this.user currency: ',this.user.currency)
      if(this.user.currency && this.user.currency!=""){
          fxLink="TWD_"+this.user.currency
          this.currency=this.user.currency

      }

      axios.get('https://free.currencyconverterapi.com/api/v6/convert?q='+fxLink+'&compact=ultra&apiKey=2d6cf65af2f8557d2378')
        .then(res => {
          // console.log(res)
          // console.log('FX:')
          // console.log(res.data[fxLink])
          if (this.user.currency!="TWD"&&this.user.currency!="NTD"){
            this.fx = res.data[fxLink]*1.04
          } else {
            this.fx = res.data[fxLink]
          }
          this.eurPrice = Math.ceil(parseFloat(this.studio.billing.price) * this.fx)
          // console.log('EURPRICE:',this.eurPrice)
        })
        .catch(() => {
          // console.log('cant get conversion rate')
          this.eurPrice = Math.ceil(parseFloat(this.studio.billing.price) * this.fx)
        })

    },
  },
  created() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
    dataLayer.push({
        'id':this.$route.params.studioId,
        'hrental_id':this.$route.params.studioId
    })

    this.setRSeen()
    this.checkQuery()
    // console.log(this.user.id);
    // console.log("this runs:")
    // get the room details and the apartment details to display
    axios.get('/studios/' + this.$route.params.studioId + '.json')
      .then((res) => {
        this.initialLoad=false
        // console.log(res)
        this.studio = res.data
        dataLayer.push({
            'price':parseInt(this.studio.billing.price),
            'margin':parseInt(this.studio.billing.price)*.2,
        })
        this.getLLBookings(this.studio)
        this.setArea(this.studio.general.location)
        this.checkLocation(this.studio.general.location)
        this.setContractLengthVal()
        axios.get('users/'+this.studio.general.landlordId+'.json')
        .then(res=>{
          if (res.data!=undefined){
            this.ll=res.data
            if (this.ll.hasOwnProperty('admin')){
              axios.get('users/'+this.ll.admin+'.json')
              .then(res=>{
                this.admin=res.data
              })
            }
            this.cancelPolVal=res.data.cancelPol
          } else {
            this.cancelPolVal=0
          }
        })
        .catch(err=>{
          console.log('error getting the users: ', err);
        })
        this.studio.textDate = moment(this.studio.general.nextAvailability).format("ll")
        this.snakeStrBedType = toSnakeCase(this.studio.featureList.bedType)
        // console.log('this.snakeStrBedType')

        if (!this.studio.contractDetails.rules.noSmoking.value) {
          this.smokingAllowed = true
        }
        // transform the youtube video link into the right link
        if(this.getId(this.studio.visual.video)!='error'){
          this.video = "https://www.youtube.com/embed/" + this.getId(this.studio.visual.video)
        }
        // console.log(this.studio.featureList.features.length)
        // set the studio features from the database
        // console.log(this.studio.featureList.furniture.length)
        for (var i = 0; i < this.studio.featureList.furniture.length+1; i++) {
          // console.log('add : ', this.studio.featureList.furniture[i])
          if (this.studioFurniture.hasOwnProperty(this.studio.featureList.furniture[i])){
            this.studioFurniture[this.studio.featureList.furniture[i]].value = true
          }
        }
        // console.log('here2 its okay')

        // set the room features from the database
        for (var j = 0; j < this.studio.featureList.features.length; j++) {
          // console.log(this.studio.featureList.features[i])
          if (this.studioFeatures.hasOwnProperty(this.studio.featureList.features[j])){
            this.studioFeatures[this.studio.featureList.features[j]].value = true
          }
        }
        this.map.center.lat = parseFloat(this.studio.general.location.lat)
        this.map.center.lng = parseFloat(this.studio.general.location.lng)

      })
      .catch(err=>{
        console.log('error getting the studios: ',err);
      })
      this.getFx()



  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
    acceptance(){
      if (this.llBR!=0){
        return Math.round(this.llBookings/this.llBR*100)
      } else {
        return 0
      }
    },

    views(){
      return this.rSeen.length
    },
    viewsThisWeek(){
      let t=this.rSeen.filter(item=>{
        return moment().subtract(1,'week').isBefore(moment(item.ts))
      })
      console.log(t);
      if (t.length>2){
        return t.length
      } else {
        return 2
      }
    },
    realViewsThisWeek(){
      let t=this.rSeen.filter(item=>{
        return moment().subtract(1,'week').isBefore(moment(item.ts))
      })
      return t.length
    },

    similarLink(){
      let link="/search?"
      if (moment(this.studio.general.nextAvailability).isBefore(moment())) {
        link=link+'mid='+moment().format("YYYY-MM-DD")+'&'
      } else {
        link=link+'mid='+moment(this.studio.general.nextAvailability).format("YYYY-MM-DD")+'&'
      }
      if (this.studio.billing.price!=undefined){
        let tPrice=parseInt(this.studio.billing.price)+2000
        link=link+'price='+tPrice+"&"
      }
      if (this.studio.featureList.privateBathroom==true){
        link=link+'propType=privateBath&'
      } else {
        link=link+'propType=studio&'
      }
      link=link+'bedType='+this.studio.featureList.bedType+'&'
      switch (this.cLengthVal) {
        case 5:
          link=link+'cLength=3'
          break;
        case 3:
          link=link+'cLength=2'
          break;
        case 1:
          link=link+'cLength=1'
          break;
        default:
          break
      }
      return link
    },

    studioDesc(){
      if (this.studio.general.description!=undefined){
        return this.studio.general.description.split("\n")
      } else {
        return ""
      }
    },

    baseUrl(){
      return config.PHOTOURL
    },
    trustVal(){
      let t=0
      if (this.studio.trustVal){
        t=Number(this.studio.trustVal)
      }
      if (this.studio.visit){
        t=Number(this.studio.visit)
        if (this.studio.trustVal && this.studio.trustVal>this.studio.visit){
          t=this.studio.trustVal
        }
      }
      return t
    },

    cancellation(){
      switch (this.cancelPolVal){
        case 4:
          return this.$t('profile.noRefund')
        case 3:
          return this.$t('profile.strict')
        case 2:
          return this.$t('profile.moderate')
        case 1:
          return this.$t('profile.flexible')
        default:
          return this.$t('profile.moderate')
      }
    },
    kitchenType:function(){
      if (this.studio.featureList.kitchenType!=undefined){

        if(this.studio.featureList.kitchenType.includes('None')){
          return this.$t('features.kitchenOptions.none')
        } else if (this.studio.featureList.kitchenType.includes('Basic')) {
          return this.$t('features.kitchenOptions.basic')
        } else {
          return this.$t('features.kitchenOptions.full')
        }
      } else {
        return ""
      }
    },
    dryBathroom:function(){
      return this.$t("details.dryBathroom")
    },
    wetBathroom:function(){
      return this.$t("details.wetBathroom")
    },
    utilities:function(){
      switch (this.studio.billing.utilities){
        case "Included in rent":
          return this.$t('billing.utilitiesOptions.included')
        case "Fixed monthly amount":
          return this.$t('billing.utilitiesOptions.fixed')
        case "Split by tenants":
        return this.$t('billing.utilitiesOptions.split')
        case "Other":
        return this.$t('billing.utilitiesOptions.other')
        default:
          return ""
      }
    },

    contractFlexibility:function(){
      switch (this.studio.contractDetails.contractFlexibility){
        case "Starts and ends any day":
          return this.$t('contractDetails.contractFlexibilityOptions.any')
        case "Starts any day for 1 month":
          return this.$t('contractDetails.contractFlexibilityOptions.oneMonth')
        case "Starts first or 15th":
          return this.$t('contractDetails.contractFlexibilityOptions.firstOr')
        case "Starts always on the first":
          return this.$t('contractDetails.contractFlexibilityOptions.first')
        default:
          return ""
      }
    },

    bedType:function(){
      switch (this.studio.featureList.bedType) {
        case "None":
          return this.$t("details.noBed")
        case "Single":
          return this.$t("details.singleBed")
        case "Double":
          return this.$t("details.doubleBed")
        default:
          return ""
      }
    },
    contractLanguage:function(){
      switch (this.studio.contractDetails.contractLanguage){
        case "English":
          return this.$t('contractDetails.contractLanguageOptions.english')
        case "English & Chinese":
          return this.$t('contractDetails.contractLanguageOptions.both')
        case "Chinese only":
          return this.$t('contractDetails.contractLanguageOptions.chinese')
        default:
          return ""
      }
    },
    contractLength:function(){
      switch(this.studio.contractDetails.contractLength){
        case "1 month":
          return this.$t('pricing.monthly')
        case "3 months":
          return this.$t('pricing.three')
        case "5 months":
        return this.$t('pricing.five')
        case "12 months":
        return this.$t('pricing.year')
        case "Open contract":
          return this.$t('titles.openContract')
        default:
         return ""
      }
    },

  },
  components: {
    Trust,
    Feature,
    Bottombar,
    Area,
    Loading,
    OtherTenant,
    OpenMapsListings,
  },
  metaInfo() {
    // console.log('our this: ', this.initialLoad)
    let meta=[
      { vmid:'identifier-url', name:'identifier-url',content:'https://myroomabroad.com'+this.$route.path},
      { vmid:'description', name:'description', content:this.initialLoad ? config.TAGLINE : this.studio.general.description.split('.')[0]},
      {vmid:'og:url', property:'og:url' ,content:'https://myroomabroad.com'+this.$route.path},
      {vmid:'og:title', property:'og:title',content:this.initialLoad ? config.TITLE : this.studio.general.title},
      {vmid:'og:description',property:'og:description',content:this.initialLoad ? config.TAGLINE : this.studio.general.description.split('.')[0]},
      {vmid:'og:image', property:'og:image', content:this.initialLoad ? 'https://res.cloudinary.com/my-room-abroad/image/upload/c_lpad,h_900,w_1400,f_auto,q_auto/v1545459923/Logo/Logo_square_LARGE_TXT_no_bg.jpg' : this.studio.visual.photos[0].url},
      {vmid:'og:image:type', property:'og:image:type',content:'image/jpg'},
      {vmid:'og:image:width', property:'og:image:width',content:'1400'},
      {vmid:'og:image:height', property:'og:image:height',content:'900'},
      {vmid:'twitter:image', name:'twitter:image',  content:this.initialLoad ? 'https://res.cloudinary.com/my-room-abroad/image/upload/c_lpad,h_900,w_1400,f_auto,q_auto/v1545459923/Logo/Logo_square_LARGE_TXT_no_bg.jpg' : this.studio.visual.photos[0].url},
      {vmid:'twitter:title', name:'twitter:title', content:this.initialLoad ? config.TITLE : this.studio.general.title},
      {vmid:'twitter:url', name:'twitter:url', content:'https://myroomabroad.com'+this.$route.path},
      {vmid:'twitter:description', name:'twitter:description', content:this.initialLoad ? config.TAGLINE : this.studio.general.description.split('.')[0]},

      // { vmid: 'description', name: 'description', content: 'loading' },
    ]
    for (var i in this.studio.visual.photos){
      meta.push({vmid:'og:image', property:'og:image', content:this.initialLoad ? 'https://res.cloudinary.com/my-room-abroad/image/upload/c_lpad,h_900,w_1400,f_auto,q_auto/v1545459923/Logo/Logo_square_LARGE_TXT_no_bg.jpg' : this.studio.visual.photos[i].url})
      meta.push({vmid:'og:image:type', property:'og:image:type',content:'image/jpg'})
      meta.push({vmid:'og:image:width', property:'og:image:width',content:'1400'})
      meta.push({vmid:'og:image:height', property:'og:image:height',content:'900'})
    }

      return {
        title:this.initialLoad ? config.TITLE : this.studio.general.title, // set a title
        titleTemplate: '%s - My Room Abroad', // title is now "My Example App - Yay!"
        meta:meta
    }
  },

}
</script>
<style lang="scss" scoped>
.summary {
    text-align: center;
    padding-left: 0;
    margin-top: 1rem;
    span {
        font-size: 3rem;
    }
}
.social-icon{
  // font-size:2em;
  height:1.5em;
}

.features {
    list-style: none;
    text-align: left;
}
.affix {
    width: 26.666666666%;
}
.sidebar {
    text-align: left;
    // position: fixed;
    // top: 125px;
    // right: 0px;
}
.section {
    margin: 2rem 0;
}
ul.features {
    padding-left: 0;
}
.md-list-item {
    min-height: 24px;
}
.bathrooms {
    cursor: help;
}
.bathroom-explained {
    font-size: 0.75rem;
}
.carousel-caption {
    color: #ffffff !important;
}
.image-text{
  text-align: center;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}
.full-height{
  height:85%;
}
.carousel-caption {
    color: #ffffff !important;
}
.carousel{
  height:100%;
}
</style>
