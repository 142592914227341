<template>
  <div>
  <v-layout  wrap align-center>
    <!-- <v-tooltip right max-width="200px">

    <span slot="activator" class="subheading trust-head pa-3 px-4" :class="trustVals.class">
      {{trustVals.headline}}
    </span>
    <span>{{trustVals.about}}</span>
  </v-tooltip> -->
  <v-dialog
      v-model="dialog"
      width="70vw"

    >

    <v-tooltip right slot="activator" class="pa-3" max-width="200px">

      <div slot="activator" class="subheading trust-head pa-3 px-4" :class="trustVals.class">
        {{trustVals.headline}}

      </div>

      <span>{{trustVals.about}}</span>
    </v-tooltip>
      <v-card >
        <v-card-text>
          <v-container grid-list-md class="pb-1">

          <v-layout   wrap>
            <v-flex class="headline pb-2" xs12>

              {{$t('trust.verification')}}
            </v-flex>
          </v-layout>
          <v-layout  wrap>
              <v-flex xs12 md7>
                {{$t('trust.verificationText')}}
              </v-flex>
        </v-layout>
        <v-layout wrap>

        </v-layout>
      </v-container>
          <v-layout  wrap>
            <v-flex xs12 sm6 md3 v-for="(type, index) in verifications" :key="index" >
              <v-container grid-list-lg>
              <v-layout  wrap>
                <v-flex xs12 class="trust-head text-xs-center" :class="type.class">
                  {{type.headline}}
                </v-flex>
                <v-flex xs12 class="trust-title" :class="type.class">
                  {{type.title}}
                </v-flex>
                <v-flex xs12>
                  {{type.about}}
                </v-flex>
              </v-layout>
            </v-container>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            flat
            @click="dialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
  <div class="" v-if="bookings>1">
    <v-flex >

      <v-icon color='primary'>loyalty</v-icon> Successful reservations: {{bookings}}

    </v-flex>
    <v-flex >

          <v-tooltip bottom >

            <div slot="activator">

              <v-icon color='primary'>how_to_reg</v-icon> Acceptance rate: {{acceptance}}%

            </div>
            <span>
              A lower acceptance rate usually means that this landlord doesn't update his rooms' availability regularly.
            </span>
          </v-tooltip>

    </v-flex>
    <v-flex >
      <v-tooltip bottom >
        <div slot="activator">
          <v-icon color='primary'>mail</v-icon> Response rate: {{respRate}}%
        </div>
        <span>
          Percentage of booking requests accepted or declined by the landlord within 48h.
        </span>
      </v-tooltip>



    </v-flex>


  </div>
</div>
</template>
<script>
export default {
  name: "",
  props:['trust','bookings', 'acceptance','respRate'],
  data: () => ({
    dialog:false,
  }),
  computed:{
    verifications:function(){
      let t=[
        {
          headline:"Exclusive Landlord",
          class:"exclusive",
          title:"My Room Abroad's Exclusive Partner",
          about:"This listing is one of our top recommendations. We have selected this landlord to be our exclusive local partner. This listing is only available on My Room Abroad",
          value:3,
        },
        {
          headline:"Visited Room",
          class:'visited',
          title:"We trust this place",
          about:"We have personally visited this place and it has earned the 'Visited by My Room Abroad' badge.",
          value:2,
        },
        {
          headline:"Verified Landlord",
          class:'verified',
          title:"This is a verified landlord",
          about:"We have verified this landlord. This means we have at least had a phone call with him to check his personal details.",
          value:1,
        },
        {
          headline:"Not yet verified",
          class:"not-verified",
          title:"We still need to verify this landlord.",
          about:"We're still validating this landlord's identity. As soon as we have done this, he will appear as Verified.",
          value:0,
        }
      ]
      if (this!=undefined){
        t=[
          {
            headline:this.$t('trust.exclHead'),
            class:"exclusive",
            title:this.$t('trust.exclTitle'),
            about:this.$t('trust.exclText'),
            value:3,
          },
          {
            headline:this.$t('trust.visitHead'),
            class:'visited',
            title:this.$t('trust.visitTitle'),
            about:this.$t('trust.visitText'),
            value:2,
          },
          {
            headline:this.$t('trust.verifHead'),
            class:'verified',
            title:this.$t('trust.verifTitle'),
            about:this.$t('trust.verifText'),
            value:1
          },
          {
            headline:this.$t('trust.naHead'),
            class:"not-verified",
            title:this.$t('trust.naTitle'),
            about:this.$t('trust.naText'),
            value:0
          }
        ]
      }
      return t
    },
    trustVals:function(){
      let t={}
      if(this!=undefined){
        switch (this.trust) {
          case 3:
          t.headline=this.$t('trust.exclHead')
          t.class="exclusive"
          t.title=this.$t('trust.exclTitle')
          t.about=this.$t('trust.exclText')
          break;
          case 2:
          t.headline=this.$t('trust.visitHead')
          t.class="visited"
          t.title=this.$t('trust.visitTitle')
          t.about=this.$t('trust.visitText')
          break
          case 1:
          t.headline=this.$t('trust.verifHead')
          t.class="verified"
          t.title=this.$t('trust.verifTitle')
          t.about=this.$t('trust.verifText')
          break
          default:
          t.headline=this.$t('trust.naHead')
          t.class="not-verified"
          t.title=this.$t('trust.naTitle')
          t.about=this.$t('trust.naText')
        }
      }
      return t

    }
  }
}
</script>
<style lang="scss" scoped>
.trust-head{
  text-transform: uppercase;
  font-weight:500;
}
.exclusive{
  background-color: rgba(0,134,179,.2);
  color:rgba(0,134,179,1);
}
.visited{
  background-color: rgba(0,191,255,.2);
  color: rgba(0,191,255,1);
}
.verified{
  background-color: rgba(0,179,0,.2);
  color:rgba(0,179,0,1);
}
.not-verified{
  background-color:rgba(128,128,128,.2);
  color:rgba(128,128,128,1)
}
.trust-title{
  background-color: transparent;
}
</style>
