<template>
<div>
  <l-map
    style="z-index:1!important; height:80vh; width: 100%"
    :zoom="mapZoom"
    :center="mapCenter"
    @update:zoom="zoomUpdated"

  >

    <l-tile-layer :url="url"    attribution='<a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>'></l-tile-layer>
    <l-marker :icon="icon" :lat-lng="[center.lat,center.lng]">
    </l-marker>
    <l-feature-group layer-type="overlay" :visible="cZoom>=13" >
      <l-marker  :options="{zIndexOffset:-900}" :icon="mrtIcon" v-for="(el,index) in mrt" :key="index"  :lat-lng="[el.lat,el.lng]">
        <l-popup style="width: 180px!important;">
          <v-layout wrap justify-center>
            <v-flex class="text-xs-center pa-2" xs12>
              {{el.name}} MRT Station
            </v-flex>
          </v-layout>
        </l-popup>

      </l-marker>
      <l-marker  :options="{zIndexOffset:-900}" :icon="schoolIcon" v-for="(el) in schools" :key="el.name"  :lat-lng="[el.lat,el.lng]">
        <l-popup style="width: 180px!important;">
          <v-layout wrap justify-center>
            <v-flex class="text-xs-center pa-2" xs12>
              {{el.name}}
            </v-flex>
          </v-layout>
        </l-popup>

      </l-marker>

    </l-feature-group>

  </l-map>
</div>
</template>

<script>
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import firebase from '../../FirebaseApp'

export default {
  props: ['center','zoom'],
  data: () =>({
    schools:[],
    cZoom:14,
    mrt:[],
    attributions:['MapTiler',"OpenStreetMap contributors"],
    url: 'https://a.tile.openstreetmap.de/{z}/{x}/{y}.png',
    icon: L.icon({
      iconUrl: 'https://res.cloudinary.com/my-room-abroad/image/upload/v1568260858/clusterIcons/blue_logo.png',
      iconSize: [60, 62],

    }),
    mrtIcon: L.icon({
      iconUrl: 'https://res.cloudinary.com/my-room-abroad/image/upload/v1568709209/clusterIcons/metro200.svg',
      iconSize: [8, 8],

    }),
    schoolIcon: L.icon({
      iconUrl: 'https://res.cloudinary.com/my-room-abroad/image/upload/v1568716400/clusterIcons/school4.png',
      iconSize: [15, 15],

    }),
    // options: {
    //   dragging:!L.Browser.mobile,
    //   dragging: true,
    // },
  }),
  firebase() {
    return {
      locations: {
        source:firebase.database().ref('locations'),
        readyCallback:function(){
          for (var i = 0; i < this.locations.length; i++) {

            if (this.locations[i].icon== 'directions_transit' && !this.locations[i].name.match(/[\u3400-\u9FBF]/) ) {
              this.mrt.push(this.locations[i])
            } else if ( this.locations[i].icon== 'school' && !this.locations[i].name.match(/[\u3400-\u9FBF]/) && this.schools.findIndex(x=>(x.lat==this.locations[i].lat && x.lng==this.locations[i].lng))==-1 ) {
              this.schools.push(this.locations[i])
            }
          }
        }
      },
    }
  },
  methods: {
    zoomUpdated (zoom) {
      this.cZoom=zoom
    },

  },
  computed:{
    mapZoom(){
      return this.zoom
    },
    mapCenter(){
      return this.center
    },
  },
  created(){
    // console.log(this.$refs);
  },
  components: {
  },
}
</script>
<style lang="scss" scoped>
</style>
