<template>
    <v-flex sm4 xs6 md3 class="feature-list" :class="{'disabled' : !feature.value}">
      <span><span :class="feature.icon"> </span> {{$t("features."+index)}}</span>
    </v-flex>


</template>
<script>
export default {
  name: "",
  props: ['feature','index'],
  data: () => ({}),
}
</script>
<style lang="scss" scoped>
.disabled {
  text-decoration: line-through;
  text-decoration-color: rgba(0,0,0,.19);
  color: rgba(0,0,0,0.38);
}
.feature-list{
  text-align:left;
  line-height: 24px
}
</style>
