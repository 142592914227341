<template>
<div id="">
  <v-layout  wrap class="room-row">
    <v-flex md2 sm3>
      {{roomNumber}}
    </v-flex>
    <v-flex md5 sm9>
      <v-layout  wrap v-if="foundCurrentTenant">
        <v-flex xs4>
          <span><span :class="'MRAIcon_'+genderIconCurrent"></span> {{genderCurrent}}</span>
          <br>
          <span>{{currentTenant.nationality}}</span>
        </v-flex>
        <v-flex xs5>
          <div class="tenant-occupation">
            {{occupation}}
          </div>
          <div class="tenant-school">
            {{currentTenant.school}}
          </div>
        </v-flex>
        <v-flex xs3>
          <div class="tenant-mod">
            <v-icon small>flight_takeoff</v-icon> <br>
            {{currentTenant.mod}}
          </div>
        </v-flex>
      </v-layout>
      <v-layout v-else wrap>
        <v-flex xs12 class="">
           ** Undisclosed **
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex md5 class="hidden-sm-and-down">
      <v-layout  wrap v-if="foundFutureTenant">
        <v-flex xs4>
          <span :class="'MRAIcon_'+futureTenant.gender.toLowerCase()"></span>
          <br>
          <span>{{futureTenant.nationality}}</span>
        </v-flex>
        <v-flex xs5>
          <div class="tenant-occupation">
            {{futureTenant.occupation}}
          </div>
          <div class="tenant-school">
            {{futureTenant.school}}
          </div>
        </v-flex>
        <v-flex xs3>
          <div class="tenant-mod">
            <v-icon small>flight_takeoff</v-icon> <br>
            {{futureTenant.mod}}
          </div>
        </v-flex>
      </v-layout>
    </v-flex>

  </v-layout>
</div>
</template>
<script>
import moment from 'moment'
import countries from "i18n-iso-countries"
import axios from 'axios'

export default {
  name: "",
  props: ['index', 'room', 'dateSet', 'type'],
  data: () => ({
    mod: "",
    defaultMessage: "Not disclosed",
    foundCurrentTenant: false,
    foundFutureTenant: false,
    tenants: {},
    currentTenant: {},
    futureTenant: {},
  }),
  methods: {
    findTheCurrentTenant() {
      let now = moment()
      // console.log('heck the tenants:');
      countries.registerLocale(require("i18n-iso-countries/langs/" + this.$i18n.locale + ".json"));
      for (var tenant in this.tenants) {
        if (this.tenants.hasOwnProperty(tenant)) {
          if (now.isBetween(moment(this.tenants[tenant].moveInDate), moment(this.tenants[tenant].moveOutDate))) {
            this.currentTenant = this.tenants[tenant]
            this.currentTenant.nationality = countries.getName(this.currentTenant.nationality, this.$i18n.locale)
            this.currentTenant.occupation = this.currentTenant.occupation.toLowerCase()
            this.currentTenant.mod = moment(this.currentTenant.moveOutDate).format('MMM, Do')
            this.foundCurrentTenant = true
          }
        }
      }
    },
    findTheFutureTenant() {
      // console.log('isDateSet:', this.dateSet)
      if (this.dateSet) {
        let mid = this.$route.query.mid
        for (var tenant in this.tenants) {
          if (this.tenants.hasOwnProperty(tenant)) {
            // console.log(moment(mid))
            if (moment(parseFloat(mid)).isBetween(moment(this.tenants[tenant].moveInDate), moment(this.tenants[tenant].moveOutDate))) {
              this.futureTenant = this.tenants[tenant]
              this.futureTenant.mod = moment(this.futureTenant.moveOutDate).format('MMM Do')
              this.foundFutureTenant = true
            }
          }
        }
      }
    },
    setGenderIcon(gender) {
      switch (gender.toLowerCase()) {
        case 'f':
          return 'female'
        case 'm':
          return 'male'
        default:
          return ''
      }

    },
    setGender(gender) {
      switch (gender.toLowerCase()) {
        case 'f':
          return this.$t('profile.female')
        case 'm':
          return this.$t('profile.male')
        default:
          return 'undefined'
      }


    },
  },
  computed: {
    roomNumber: function() {
      if (this.room != undefined) {
        if (this.type=="apartment"){
          return this.$t('newListing.wholeFlat')
        } else {
          return this.room.number
        }
      }
    },
    occupation(){
      if (this.currentTenant.occupation!=undefined){

        return this.$t("otherTenants."+this.currentTenant.occupation)
      } else {
        return ""
      }

    },
    genderIconCurrent: function() {
      return this.setGenderIcon(this.currentTenant.gender)
    },
    genderCurrent: function() {
      return this.setGender(this.currentTenant.gender)
    },
    genderIconFuture: function() {
      return this.setGenderIcon(this.futureTenant.gender)
    },
    genderFuture: function() {
      return this.setGender(this.futureTenant.gender)
    },
  },
  created() {
    // console.log(this.type);
      if (this.type == 'room') {
        // console.log('rooms/'+this.index+"/tenants.json");
        axios.get('rooms/'+this.index+"/tenants.json")
        .then(res=>{
          this.tenants=res.data.tenants
          this.findTheCurrentTenant()
          this.findTheFutureTenant()
        })
      } else if (this.type=='studio'){
        axios.get('studios/'+this.index+"/tenants.json")
        .then(res=>{
          this.tenants=res.data.tenants
          this.findTheCurrentTenant()
          this.findTheFutureTenant()
        })
        .catch((err)=>{
          console.log('Err:', err);
        })
    }
  }

}
</script>
<style lang="scss" scoped>
.tenant-card {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}
.room-row {
    margin-bottom: 0.5rem;
}
</style>
